@import "../../../style/function.scss";

.userTerms {
  color: #1b1b1b;
  font-size: dim(17);
  padding: 0 15%;
  text-align: left;

  p {
    padding: 0 56px;
    text-indent: dim(0);
  }
  blockquote {
    padding: 0 0;
  }
  .checkboxItem {
    display: flex;
    justify-content: center;
    padding: dim(30) 0;
    font-size: dim(18);
  }
  .buttonItem {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 499px) {
  p {
    padding: 0;
  }
  .userTerms {
    padding: 0;
  }
}

@media screen and (max-width: 320px) {
  .userTerms {
    padding: 0;
  }
  p {
    padding: 0;
  }
}
