@import "../../style/function.scss";

.app {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 160px 30px 250px;
  z-index: 100;
}

.logo {
  width: dim(240);
  z-index: 100;
}

@media screen and (max-width: 520px) {
  .app {
    padding-left: 40px !important;
    padding-right: 10px !important;
  }
}
.header_bottom {
  height: 2px;
  background: url("/picInner/round.png") no-repeat center;
  text-align: center;
}
